<script setup lang="ts">
import { BaseDateInput, BaseInput, BaseInputWithLines, BaseAddressInput, BasePhoneNumberInput } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    default:
      'Policy Details - for completion by the Patient or Parent/Legal Guardian (if patient is under 18 years of age)'
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="vhi-policy-details-title" :title="title" />
    <BaseInputWithLines
      id="policy-no"
      name="policyNo"
      class="mt-6"
      label="Quote Policy No."
      :max-length="8"
      helper-text="from you Vhi membership card"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('policy-no')"
    />
    <BaseInput
      id="patient-name"
      name="patientForename"
      class="mt-6 sm:mt-12"
      label="Patient's Name*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-name')"
    />
    <BaseInput
      id="patient-surname"
      name="patientSurname"
      class="mt-6 sm:mt-12"
      label="Patient's Surname*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-surname')"
    />
    <BaseAddressInput
      id="patient-address"
      name="patientAddress"
      class="mt-6 sm:mt-12"
      label="Patient's Address*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-address')"
    />
    <BaseInput
      id="policy-holder-name"
      name="policyHolderName"
      class="mt-6 sm:mt-12"
      label="Policy Holder's Name"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('policy-holder-name')"
      :enableValidation="false"
    />
    <BaseDateInput
      id="patient-dob"
      name="birthDate"
      class="mt-6 sm:mt-12"
      label="Patient's Date of Birth*"
      :edit-mode="editMode"
      :enableValidation="false"
    />
    <BasePhoneNumberInput
      id="telephone"
      name="telephone"
      class="mt-6 sm:mt-12"
      label="Contact Telephone No."
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('telephone')"
      :enableValidation="false"
    />
    <BaseInput
      id="email"
      name="email"
      class="mt-6 sm:mt-12"
      label="Email Address"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('email')"
      :enableValidation="false"
    />
    <ul class="my-8">
      <p class="font-bold mb-2">Please check that you have entered your Policy Number</p>
      <p>
        Please note that the address you provide is purely for data validation purposes. If you need to update your
        contact details or membership/personal data, please contact our Customer Services Helpline at
        <a href="tel:(056) 444 444">(056) 444 4444.</a>
      </p>
    </ul>
  </div>
</template>
